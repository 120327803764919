import React from "react"
import 'uikit/dist/css/uikit.css'
//import UIkit from 'uikit'
import Layout from "../layouts"
import Header from "../components/header/header";
import { bannerMan, headerMan } from "../utils/generalInformation";
import Banner from "../components/banner/banner";


const Everton = () => {


  return (
    <Layout
      SEOTitle={"Manchester United - United Legal Assistance"}
      SEODescription={"Our Manchester United Hospitality: Fancy coming to the game with United Legal Team? We’re paying! Can we count on you in?"}
      footerDisclaimer={""}>

      <Header props={headerMan} />

      <Banner props={bannerMan} />

    </Layout>
  )

}

export default Everton