import React, { useEffect } from "react"
import "./header.scss"
import BackgroundImage from "gatsby-background-image"
import CustomMarkdown from "../../utils/customMarkdown"


const Header = ({ props }) => {


    var bgFluidImage = { fluid: { srcSet: props.image, src: props.pngImage, aspectRatio: 16 / 9, sizes: '(max-width: 800px) 100vw, 800px', } }

    console.log()

    useEffect(() => {
        if (bgFluidImage && bgFluidImage.fluid && bgFluidImage.fluid.srcSet) {
            const preloadLink = document.createElement("link");
            preloadLink.href = bgFluidImage.fluid.srcSet;
            preloadLink.rel = "preload";
            preloadLink.as = "image";
            preloadLink.fetchPriority = "high";
            document.head.appendChild(preloadLink);
        }
    }, [])

    return (
        <>

            <section role="img" className="background-zindex">
                <BackgroundImage fluid={bgFluidImage.fluid || ["rgba(0,0,0,0)"]}>
                    <div className={props.Aligned ? "header header-container" : "header header-container-gradient"} uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: .header-transition; delay: 500; repeat: false">
                        <div className={props.Aligned ? "simple-header header-transition" : "header-content header-transition"}>

                            <div className="logo">
                                {props.Icon ?
                                    <div className="secondary-image">
                                        <img src={props.Icon} alt="header image" ></img>
                                    </div>
                                    : ''
                                }
                            </div>

                            {
                                props.Title ?
                                    <h1>{props.Title}</h1>
                                    : ""
                            }

                            {
                                props.Title && props.Subtitle ?
                                    <b />
                                    :
                                    ""
                            }

                            {
                                props.Subtitle ?
                                    Array.isArray(props.Subtitle) ?
                                        props.Subtitle.map((itemText, index) => {
                                            return (
                                                <>
                                                    <p className="aligned-subtitle" key={index}>{itemText.text}</p>
                                                </>
                                            )
                                        })
                                        :
                                        props.Subtitle.split('#')?.length > 1 ?
                                            <div className="subtitle-block">
                                                <p>{props.Subtitle.split('#')[0]}</p>
                                                <p>{props.Subtitle.split('#')[1]}</p>
                                                {props.Subtitle.split('#')[2] ? <span className="split-subtitle">{props.Subtitle.split('#')[2]}</span> : ""}
                                            </div>
                                            :
                                            <p>{props.Subtitle}</p>
                                    : ""

                            }

                            {props.FirstCTATitle || props.SecondCTATitle ?
                                <div className="buttons">
                                    {
                                        props.FirstCTATitle ?
                                            <div className="header__cta">
                                                <a rel="noopener noreferrer" href={props.FirstCTAURL} className="cta-first">{props.FirstCTATitle}</a>
                                            </div>
                                            : ""
                                    }
                                    {
                                        props.SecondCTATitle ?
                                            <div className="header__cta">
                                                <a rel="noopener noreferrer" href={props.SecondCTAURL} className="cta-second">{props.SecondCTATitle}</a>
                                            </div>
                                            : ""
                                    }
                                </div>
                                : ""
                            }

                        </div>
                    </div>
                </BackgroundImage>
            </section>

        </>
    )
}

export default Header